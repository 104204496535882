import logo from '../Assets/images/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    let location = useLocation();
    let lang = '';

    if(location.pathname.includes('en')) {
        lang = 'en';
    } else {
        lang = 'ka';
    }

    return (
        <div className="Header flex items-center h-20 relative overflow-hidden py-4 bg-[#f1f8fe]">
            <div className="container mx-auto flex justify-between items-center h-20 relative overflow-hidden py-4">
                <img src={logo} alt="" />
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-8">
                    <div
                        onClick={() => {
                            const locale = lang === 'en' ? 'ka' : 'en';
                            navigate("/"+locale);
                        }} className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-4 px-6 py-2.5 rounded-[200px] bg-[#3f5598]">
                        <span className="flex-grow-0 flex-shrink-0 text-2xl font-bold text-center text-white cursor-pointer select-none">{lang === 'en' ? 'GEO' : 'ENG'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};