import locationIcon from '../Assets/images/location.svg';
import emailIcon from '../Assets/images/email.svg';
import mobileIcon from '../Assets/images/mobile.svg';
import callIcon from '../Assets/images/call.svg';
import facebookIcon from '../Assets/images/facebook.svg';
import Translate from '../Data/translate.json';
import { useLocation } from 'react-router-dom';

export default function SearchResult({ agencies }) {
    let location = useLocation();
    let lang = '';

    if(location.pathname.includes('en')) {
        lang = 'en';
    } else {
        lang = 'ka';
    }

    return (
    <div className="SearchResult pt-8 pb-24 flex flex-wrap container justify-center mx-auto relative z-10 gap-10">
        {agencies.length === 0 ? (
            <div className="text-error text-5xl font-medium text-center text-[#f1f1f1]">{Translate['no_results_found_'+lang]} <br /><small>{Translate['try_again_'+lang]}</small></div>
        ) : ''}
        {agencies.map(function(agency, i){
        return (<div key={i} className="SearchResultItem flex flex-col w-1/4 justify-center items-start flex-grow overflow-hidden gap-7 p-7 rounded-[20px] bg-white border border-[#f7f7f7]">
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3">
                <img className="SearchResultItemImg" src={require('../Assets/agencies/'+agency.ID+'.svg')} alt="" />
                <p className="flex-grow-0 flex-shrink-0 text-l font-bold text-left uppercase cursor-default text-[#3f5598]">
                    {agency.agency}
                </p>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 cursor-default">
                    <img src={locationIcon} alt="" />
                    <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#4a4d53]">
                        {agency.address}
                    </p>
                </div>
                <a href={'mailto:'+agency.email} className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2.5 cursor-pointer">
                    <img src={emailIcon} alt="" />
                    <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#4a4d53]">
                        {agency.email}
                    </p>
                </a>
                <a href={'tel:'+agency.mobile} className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2.5 cursor-pointer">
                    <img src={mobileIcon} alt="" />
                    <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#4a4d53]">{agency.mobile}</p>
                </a>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4">
                <a href={'tel:'+agency.mobile} className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 px-6 py-3 rounded-[99px] bg-[#071643]">
                    <img src={callIcon} alt="" />
                    <p className="flex-grow-0 flex-shrink-0 text-lg font-semibold text-center text-white">
                        {agency.mobile}
                    </p>
                </a>
                <a href={agency.facebook} target="_blank" rel="noreferrer" className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 px-6 py-3 rounded-[99px] bg-white border-2 border-[#071643]">
                    <img src={facebookIcon} alt="" />
                    <p className="flex-grow-0 flex-shrink-0 text-lg font-semibold text-center text-[#071643]">
                        Messenger
                    </p>
                </a>
            </div>
        </div>)
        })}
    </div>
    );

}