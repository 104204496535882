import { useState } from 'react';
import mapImg from '../Assets/images/map.svg';
import scrollImg from '../Assets/images/scroll.svg';
import searchImg from '../Assets/images/search.svg';
import Select from 'react-select';
import SearchResult from "./SearchResult";
import Translate from '../Data/translate.json';
import AgenciesData from '../Data/agencies.json';
import { useLocation } from 'react-router-dom';

export default function SearchForm() {

    let location = useLocation();
    let lang = '';

    if(location.pathname.includes('en')) {
        lang = 'en';
    } else {
        lang = 'ka';
    }

    const [searchValue, setSearchValue] = useState("");
    const [selectedCity, setSelectedCity] = useState(null);

    const handleChange = (event) => {
        const searchInputValue = event.target.value;

        if (searchInputValue.length > 0) {
            setSearchValue(searchInputValue);
        } else {
            setSearchValue("");
        }
    };

    var lookup = {};
    var items = AgenciesData;
    var SearchResultData = [];
    const cities = [];
    const city_options = [];

    for (var item, i = 0; item = items[i++];) {
        var name = item.agency.toLowerCase();
        if(lang === 'ka') {
            item.address = item.address_ka;
            item.city = item.city_ka;
        } else if(lang === 'en') {
            item.address = item.address_en;
            item.city = item.city_en;
        }

        if(!cities.includes(item.city)) {
            city_options.push({ value: item.city, label: item.city });
            cities.push(item.city);
        }


        if (searchValue !== '' && name.includes(searchValue.toLowerCase())) {
            lookup[name] = 1;
            if(selectedCity && selectedCity.value === item.city) {
                SearchResultData.push(item);
            } else if(selectedCity === null) {
                SearchResultData.push(item);
            }
        } else if (searchValue === '') {
            if(selectedCity && selectedCity.value === item.city) {
                SearchResultData.push(item);
            } else if(selectedCity === null) {
                SearchResultData.push(item);
            }
        }
    }

    /*
    const district_options = [
        { value: '3-1', label: 'ზასტავა' },
        { value: '3-2', label: 'საფიჩხია' },
        { value: '3-3', label: 'გორა' },
    ];

    const [selectedDistrict, setSelectedDistrict] = useState(null);
    */

    return (
    <div className="SearchForm">
        <div className="container mx-auto relative">
            <img className="SearchFormMap" src={mapImg} alt="" />
            <div className="SearchFormContent container-sm mx-auto px-48 py-48">
                <p className="SearchFormTitle text-6xl font-bold text-center text-[#f1f1f1]">
                    {Translate['hero_title_'+lang]}
                </p>

                <div className="flex justify-center items-center mt-14">
                    <div className="relative w-4/6 py-4 overflow-hidden rounded-[200px] bg-[#424c6d] justify-start items-center gap-3 text-base text-center text-white">
                        <div className={searchValue !== "" ? "SearchFormPlaceholder hide w-full flex justify-center items-center text-center" : "SearchFormPlaceholder w-full flex justify-center items-center text-center"}>
                            <img src={searchImg} alt="" />
                            <span>{Translate['sub_title_'+lang]}</span>
                        </div>

                        <input
                            type="text"
                            name="search_agency"
                            onChange={handleChange}
                            className="SearchFormInput w-full overflow-hidden rounded-[200px] bg-[#424c6d] flex justify-start items-center text-base text-center text-white" />
                            
                    </div>
                </div>

                <div className="flex justify-center items-center mt-14">
                    <img className="SearchFormScroll" src={scrollImg} alt="" />
                </div>

            </div>

            <hr className="mt-14" />
            <div className="SearchFormFilters flex container px-36 justify-center items-center gap-8">
                <div className="flex container justify-center px-36 mt-4 p-6 gap-8">
                    <div className="SearchFormCity w-3/6">
                        <Select
                            classNamePrefix='filter'
                            placeholder={<div>{Translate['choose_city_'+lang]}</div>}
                            defaultValue={selectedCity}
                            onChange={setSelectedCity}
                            options={city_options}
                        />
                    </div>
                    {/*
                    <div className="SearchFormDistrict w-3/6">
                        <Select
                            classNamePrefix='filter'
                            placeholder={<div>აირჩიე უბანი</div>}
                            defaultValue={selectedDistrict}
                            onChange={setSelectedDistrict}
                            options={district_options}
                        />
                    </div>
                    */}
                </div>
            </div>
            <hr />
        </div>

        <SearchResult agencies={SearchResultData} />
    </div>
    );

}