import { HashRouter as Router, Route, Routes } from "react-router-dom";
import './Assets/css/App.css';
import Header from "./Components/Header";
import Home from "./Components/Home";

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element ={<Home />} />
          <Route path="/en" element ={<Home />} />
          <Route path="/ka" element ={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
